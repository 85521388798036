export const isLocal = () => {
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1']
  return LOCAL_DOMAINS.includes(window.location.hostname)
}

export const isBestGoldenOfferCom = () => {
  const BEST_GOLDEN_OFFER_COM = ['bestgoldenoffer.com']
  return BEST_GOLDEN_OFFER_COM.includes(window.location.hostname)
}

export const isStayFitUBodyPro = () => {
  const STAY_FIT_U_BODY_PRO = ['stayfitubody.pro']
  return STAY_FIT_U_BODY_PRO.includes(window.location.hostname)
}

export const isUBodyRu = () => {
  const U_BODY_RU = ['u-body.ru']
  return U_BODY_RU.includes(window.location.hostname)
}

export const isBodyMagicSnapPro = () => {
  const BODY_MAGIC_SNAP_PRO = ['body.magicsnap.pro']
  return BODY_MAGIC_SNAP_PRO.includes(window.location.hostname)
}
